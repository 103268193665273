<template>
  <el-row :gutter="30">
    <el-col :span="24" v-if="registerForm">
      <el-form :model="registerForm" ref="registerForm" :rules="registerRules" label-width="0px">
        <el-form-item prop="login">
          <el-input v-model="registerForm.login" placeholder="email" clearable type="email">
            <template slot="prepend"><i class="el-icon-user"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="registerForm.password" placeholder="Senha" clearable>
            <template slot="prepend"><i class="el-icon-lock"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input type="password" v-model="registerForm.confirmPassword" placeholder="Confirmar senha" clearable>
            <template slot="prepend"><i class="el-icon-lock"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="nome">
          <el-input v-model="registerForm.nome" placeholder="Nome" clearable>
            <template slot="prepend"><i class="el-icon-user-solid"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="datanascimento">
          <el-date-picker v-model="registerForm.dataNascimento" type="date" placeholder="Data de nacimento"
            :editable="false" format="dd/MM/yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
            @focus="openPicker">
            <template slot="prepend"><i class="el-icon-date"></i></template>
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="peso">
          <el-input type="number" step="0.1" v-model="registerForm.peso" placeholder="Peso" clearable>
          </el-input>
        </el-form-item>
        <el-form-item prop="altura">
          <el-input type="number" step="0.01" v-model="registerForm.altura" placeholder="Altura" clearable>
          </el-input>
        </el-form-item>
        <el-form-item prop="sexo">
          <el-select v-model="registerForm.sexo" placeholder="Sexo">
            <el-option label="Masculino" value="M"></el-option>
            <el-option label="Feminino" value="F"></el-option>
            <el-option label="Outro" value="O"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="pais">
          <el-select v-model="registerForm.pais" placeholder="País">
            <el-option label="Brasil" value="Brasil"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="estado">
          <el-input v-model="registerForm.estado" placeholder="Estado" maxlength="2" clearable>
          </el-input>
        </el-form-item>
        <el-form-item prop="cidade">
          <el-input v-model="registerForm.cidade" placeholder="Cidade" clearable></el-input>
        </el-form-item>
        <el-form-item prop="rua">
          <el-input v-model="registerForm.rua" placeholder="Rua" clearable></el-input>
        </el-form-item>
        <el-form-item prop="bairro">
          <el-input v-model="registerForm.bairro" placeholder="Bairro" clearable></el-input>
        </el-form-item>
        <el-form-item prop="foto">
          <el-upload v-if="!registerForm.foto" class="upload-demo" :action="uploadAction"
            :headers="{ 'api-key': '3sd5f$%465a1gva56dfg@4w54654wt6w52y4w5245*&(*325y' }" :on-change="handleFileChange"
            :before-upload="handleBeforeUpload" accept="image/png,image/jpeg,image/jpg" :limit="1" :auto-upload="true"
            list-type="picture-card">
            <el-button type="primary">Selecionar Foto</el-button>
          </el-upload>
          <div v-else>
            <img :src="registerForm.foto" alt="Foto" style="width: 100px; height: 100px;">
            <el-button @click="onRemove">
              <el-icon name="close" class="remove"></el-icon>
            </el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="cadastrar">Registrar</el-button>
          <el-button @click="voltar">Cancelar</el-button>
          <el-button type="danger" @click="excluirDados">Excluir Meus dados</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import authService from '@/services/authService';

export default {

  data() {
    return {
      registerForm: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      uploadAction: process.env.VUE_APP_API_URL + '/files/upload',
      registerRules: {
        nome: [
          { required: true, message: 'Por favor, insira o seu nome', trigger: 'blur' }
        ],
        dataNascimento: [
          { required: true, message: 'Por favor, insira a data de nascimento', trigger: 'blur' }
        ],
        peso: [
          { required: true, message: 'Por favor, insira o peso', trigger: 'blur' }
        ],
        altura: [
          { required: true, message: 'Por favor, insira a altura', trigger: 'blur' }
        ],
        sexo: [
          { required: true, message: 'Por favor, selecione o sexo', trigger: 'change' }
        ],
        rua: [
          { required: true, message: 'Por favor, insira a rua', trigger: 'blur' }
        ],
        bairro: [
          { required: true, message: 'Por favor, insira o bairro', trigger: 'blur' }
        ],
        cidade: [
          { required: true, message: 'Por favor, insira a cidade', trigger: 'blur' }
        ],
        estado: [
          { required: true, message: 'Por favor, insira o estado', trigger: 'blur' }
        ],
        pais: [
          { required: true, message: 'Por favor, insira o país', trigger: 'blur' }
        ],
        foto: [
          { required: false, message: 'Por favor, selecione uma foto', trigger: 'change' }
        ],
        login: [
          { required: true, message: 'Por favor, insira um email válido', trigger: 'blur', type: 'email' }
        ],
        password: [
          { required: false, message: 'Por favor, insira a senha', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: false, message: 'Por favor, confirme sua senha', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ]
      }
    }
  },

  mounted() {
    authService.dadosUsuario().then(response => {
      this.registerForm = {
        ...response.data,
        password: '',
        confirmPassword:''
      };
    });
  },

  methods: {
    voltar() {
      this.$router.back();
    },
    onRemove() {
      this.registerForm.foto = null;
    },
    validateCPF(rule, value, callback) {
      if (!value) {
        return callback(new Error('CPF é obrigatório'));
      }

      // Função de validação de CPF
      function validarCPF(cpf) {
        cpf = cpf.replace(/[^\d]+/g, '');

        if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
          return false;
        }

        let soma = 0;
        let resto;

        for (let i = 1; i <= 9; i++) {
          soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
          resto = 0;
        }

        if (resto !== parseInt(cpf.substring(9, 10))) {
          return false;
        }

        soma = 0;

        for (let i = 1; i <= 10; i++) {
          soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
          resto = 0;
        }

        return resto === parseInt(cpf.substring(10, 11));
      }

      if (!validarCPF(value)) {
        callback(new Error('CPF inválido'));
      } else {
        callback();
      }
    },
    openPicker() {
      this.$refs.datePicker.showPicker();
    },
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isImageFormat = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);

      if (!isImageFormat) {
        this.showNotification('Erro', 'O formato do arquivo deve ser PNG, JPG ou JPEG', 'error');
      }
      if (!isLt2M) {
        this.showNotification('Erro', 'O tamanho do arquivo não pode exceder 2MB', 'error');
      }

      return isLt2M && isImageFormat;
    },
    openRegisterModal() {
      this.showRegisterModal = true;
    },
    async cadastrar() {
      this.$refs.registerForm.validate(async (valid) => {
        if (!valid) {
          this.showNotification('Erro', 'Por favor, preencha todos os campos obrigatórios corretamente.', 'error');
          return;
        }
        try {
          const formData = new FormData();
          formData.append('login', this.registerForm.login);
          formData.append('password', this.registerForm.password);
          formData.append('nome', this.registerForm.nome);
          formData.append('dataNascimento', this.registerForm.dataNascimento);
          formData.append('peso', this.registerForm.peso);
          formData.append('altura', this.registerForm.altura);
          formData.append('sexo', this.registerForm.sexo);
          formData.append('rua', this.registerForm.rua);
          formData.append('bairro', this.registerForm.bairro);
          formData.append('cidade', this.registerForm.cidade);
          formData.append('estado', this.registerForm.estado);
          formData.append('pais', this.registerForm.pais);
          formData.append('foto', this.registerForm.foto);

          if(this.registerForm.foto) {
            localStorage.setItem('foto', this.registerForm.foto);
          }

          await authService.atualizar(formData);
          this.showNotification('Sucesso', 'Salvo com sucesso!', 'success');
          this.showRegisterModal = false;
        } catch (error) {
          this.showNotification('Erro ao atualizar', 'Ocorreu um erro ao tentar atualizar o usuário.', 'error');
          console.log(error);
        }
      });
    },
    validateConfirmPassword(rule, value, callback) {
      console.log('value', value);
      console.log('this.registerForm.password', this.registerForm.password);

      if (value !== this.registerForm.password) {
        callback(new Error('As senhas não correspondem'));
      } else {
        callback();
      }
    },
    handleFileChange(file,raw) {
      this.registerForm.foto = raw[0].response;
    },
    async excluirDados() {
      try {
          await this.$confirm('Esta ação, não pode ser desfeita, você perdera acesso as partidas e dados estatisticos. Tem certeza que deseja excluir seus dados?', 'Excluir Dados', {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            type: 'warning',
          });

          await authService.delete()
          .then(async () => {
              this.$message({
              type: 'success',
              message: 'Dados excluídos com sucesso!',
            });
            localStorage.removeItem('user');
            localStorage.removeItem('foto');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            this.$router.push('/');
          })
          .catch(() => this.$message({
            type: 'warning',
            message: 'Não é possivel excluir, entre en contato com o suporte .',
          }));

        } catch (error) {
          this.$message({
            type: 'info',
            message: 'A exclusão foi cancelada.',
          });
        }
    },
    showNotification(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        duration: 3000
      });
    }
  }
}
</script>
<style scoped>
.el-row {
  padding: 10px;
}

.el-col {
  display: flex;
  justify-content: center;
}

.el-form {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.el-form-item {
  margin-bottom: 15px;
}

.el-input,
.el-select,
.el-date-picker {
  width: 100%;
}

.el-button {
  width: 100%;
}

.upload-demo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .el-form {
    max-width: 600px;
  }

  .el-button {
    width: auto;
  }
}

img {
  width: 100px;
  height: 100px;
  margin: 10px auto;
  display: block;
}

.remove {
  display: inline-block;
  margin-left: 5px;
  color: #f56c6c;
  cursor: pointer;
}
</style>

