
import api from './api';
const autheticationService = {
    login: async (data) => {
        localStorage.clear();
        const response = await api.post('/auth/login', data);
        const token = response.data.token;
        const user = response.data.user;
        const foto = response.data.foto
        const role = response.data.role
        localStorage.setItem('token', token);
        localStorage.setItem('user', user);
        localStorage.setItem('role', role);
        localStorage.setItem('foto', foto);
        return response.data;
    },
    register: async (data) => {
        const response = await api.post('/auth/register', data);
        return response.data;
    },
    atualizar: async (data) => {
        const response = await api.post('/auth/update', data);
        return response.data;
    },

    delete: async () => {
        const response = await api.delete(`/auth/delete`);
        return response.data;
    },

    dadosUsuario: () => {
        return api.get(`/dados-usuario`);
    },

    usuarios: (page = 0, size = 10) => {
        return api.get(`/usuarios?page=${page}&size=${size}`).then(response => response.data);
    },
    atualizarole: (id, role) => {
        return api.post(`/usuarios/${id}/role`, role);
    }
}
export default autheticationService