<template>
  <el-form class="margin-top" ref="partidaForm" :model="partida" :rules="rules" label-width="120px">
    <div class="form-row">
      <el-form-item label="Campo" prop="campo.id">
        <el-select v-model="partida.campo.id" placeholder="Selecione o campo">
          <el-option
            v-for="campo in campos"
            :key="campo.id"
            :label="campo.nome"
            :value="campo.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Time 1" prop="time1.id">
        <el-select v-model="partida.time1.id" placeholder="Selecione o time 1" @change="mudaTime1">
          <el-option
            v-for="time in times1"
            :key="time.id"
            :label="time.nome"
            :value="time.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Time 2" prop="time2.id">
        <el-select v-model="partida.time2.id" placeholder="Selecione o time 2" @change="mudaTime2">
          <el-option
            v-for="time in times2"
            :key="time.id"
            :label="time.nome"
            :value="time.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="form-row">
      <el-form-item label="Cor do Time 1" prop="corTime1">
        <el-select v-model="partida.corTime1" placeholder="Selecione a cor do Time 1" @change="atualizaJogadores">
          <el-option label="Branco" value="Branco"></el-option>
          <el-option label="Preto" value="Preto"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Cor do Time 2" prop="corTime2">
        <el-select v-model="partida.corTime2" placeholder="Selecione a cor do Time 2" @change="atualizaJogadores">
          <el-option label="Branco" value="Branco"></el-option>
          <el-option label="Preto" value="Preto"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Data da Partida" prop="data">
        <el-date-picker
          v-model="partida.data"
          type="date"
          placeholder="Selecione a data"
        ></el-date-picker>
      </el-form-item>
    </div>
    <div class="form-row">
      <el-form-item label="Hora de Início" prop="horaInicio">
        <el-time-select
          v-model="partida.horaInicio"
          placeholder="Selecione a hora de início"
          :picker-options="{
            start: '07:00',
            step: '01:00',
            end: '22:00'
          }"
        ></el-time-select>
      </el-form-item>
      <el-form-item label="Hora de Fim" prop="horaFim">
        <el-time-select
          v-model="partida.horaFim"
          placeholder="Selecione a hora de fim"
          :picker-options="{
            start: '08:00',
            step: '01:00',
            end: '23:59'
          }"
        ></el-time-select>
      </el-form-item>
    </div>
    
    <!-- Cards para exibir os jogadores -->
    <el-card class="player-card" v-if="jogadoresTime1.length > 0">
        <div slot="header">Jogadores do Time 1</div>
          <div class="container-jogador" v-for="jogador in jogadoresTime1" :key="jogador.id">
            <div class="top-10"><span>{{ jogador.usuario.nome }}</span></div>
            <div class="top-10">Posição: 
              <el-select v-model="jogador.posicao" placeholder="Selecione a posição" style="width: 100%" @change="validarGoleiroUnico(jogador.posicao, 'time1', jogador.id)">
                <el-option label="Goleiro" value="goleiro"></el-option>
                <el-option label="Defesa" value="defesa"></el-option>
                <el-option label="Meio-campo" value="meio-campo"></el-option>
                <el-option label="Atacante" value="atacante"></el-option>
              </el-select>
            </div>
            <div class="top-10">
              <span v-if="jogador.posicao === 'goleiro'">Camisa: <el-input type="number" :value="partida.corTime1 === 'Branco' ? 1 : 7" style="width: 100%;" :disabled="true"></el-input></span>
              <el-select 
                v-else v-model="jogador.numero" 
                placeholder="Selecione o numero" 
                style="width: 100%"
                @change="validarNumeroUnico(jogador.numero, 'time1', jogador.id)"
                >
                <el-option 
                  v-for="numero in (partida.corTime1 === 'Branco' ? numeroTimeBranco : numeroTimePreto)" 
                  :label="numero" 
                  :value="numero" 
                  :key="numero"
                  >
                </el-option>
              </el-select>
            </div>
          </div>
      </el-card>
      
      <el-card class="player-card" v-if="jogadoresTime2.length > 0">
        <div slot="header">Jogadores do Time 2</div>
          <div class="container-jogador" v-for="jogador in jogadoresTime2" :key="jogador.id">
            <div class="top-10"><span>{{ jogador.usuario.nome }}</span></div>
            <div class="top-10">Posição: 
              <el-select v-model="jogador.posicao" placeholder="Selecione a posição" style="width: 100%" @change="validarGoleiroUnico(jogador.posicao, 'time2', jogador.id)">
                <el-option label="Goleiro" value="goleiro"></el-option>
                <el-option label="Defesa" value="defesa"></el-option>
                <el-option label="Meio-campo" value="meio-campo"></el-option>
                <el-option label="Atacante" value="atacante"></el-option>
              </el-select>
            </div>
            <div class="top-10">
              <span v-if="jogador.posicao === 'goleiro'">Camisa: <el-input type="number" :value="partida.corTime2 === 'Branco' ? 1 : 7" style="width: 100%;" :disabled="true"></el-input></span>
              <el-select 
                v-else v-model="jogador.numero" 
                placeholder="Selecione o numero" 
                style="width: 100%"
                @change="validarNumeroUnico(jogador.numero, 'time2', jogador.id)"
                >
                <el-option 
                  v-for="numero in (partida.corTime2 === 'Branco' ? numeroTimeBranco : numeroTimePreto)" 
                  :label="numero" 
                  :value="numero" 
                  :key="numero"
                  >
                </el-option>
              </el-select>
            </div>
          </div>
      </el-card>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">Salvar</el-button>
      <el-button @click="resetForm">Cancelar</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import campoServices from '@/services/campoService';
import partidaServices from '@/services/partidaService';
import timeService from '@/services/timeService';
import jogadorService from '@/services/jogadorService';

export default {
  data() {
    return {
      id: "",
      partida: {
        campo: { id: "" },
        time1: { id: "" },
        time2: { id: "" },
        corTime1: '',
        corTime2: '',
        data: '',
        horaInicio: '',
        horaFim: '',
        dataCriacao: new Date().toISOString().split('T')[0],
        horaCriacao: new Date().toTimeString().split(' ')[0]
      },
      campos: [],
      times: [],
      numeroTimeBranco: [2,3,4,5,6],
      numeroTimePreto: [8,9,10,11,12],
      times1: [],
      times2: [],
      jogadoresTime1: [],
      jogadoresTime2: [],
      rules: {
        'campo.id': [{ required: true, message: 'Selecione o campo', trigger: 'change' }],
        'time1.id': [{ required: true, message: 'Selecione o Time 1', trigger: 'change' }],
        'time2.id': [{ required: true, message: 'Selecione o Time 2', trigger: 'change' }],
        corTime1: [{ required: true, message: 'Selecione a cor do Time 1', trigger: 'change' }],
        corTime2: [{ required: true, message: 'Selecione a cor do Time 2', trigger: 'change' }],
        data: [{ required: true, message: 'Selecione a data da partida', trigger: 'change' }],
        horaInicio: [{ required: true, message: 'Selecione a hora de início', trigger: 'change' }],
        horaFim: [{ required: true, message: 'Selecione a hora de fim', trigger: 'change' }]
      }
    };
  },
  methods: {
    onSubmit() {

      if(this.jogadoresTime1.length < 6 || this.jogadoresTime2.length < 6) {
        this.$message.error('Selecione 6 jogadores para cada time!');
        return;
      }

      if(this.verificaJogadorSemNumeroEPosicao(this.jogadoresTime1) || this.verificaJogadorSemNumeroEPosicao(this.jogadoresTime2)) {
        this.$message.error('Há jogadores sem numero ou posição!');
        return;
      }

      this.$refs.partidaForm.validate(async (valid) => {
        if (valid) {
          if (this.id) {
            await partidaServices.update(this.id, {...this.partida, jogadoresTime1: this.jogadoresTime1, jogadoresTime2: this.jogadoresTime2})
            this.$message.success('Atualizado com sucesso');
          } else {
            await partidaServices.save({...this.partida, jogadoresPartida: [...this.formataJogadores(this.jogadoresTime1, this.partida.time1.id), ...this.formataJogadores(this.jogadoresTime2, this.partida.time2.id)]});
            this.$message.success('Salvo com sucesso');
          }
          this.$router.back();
        }
      });
    },
    verificaJogadorSemNumeroEPosicao(jogadores) {
      return jogadores.filter(j => j.posicao !== 'goleiro').some(jogador => !jogador.numero || !jogador.posicao);
    },
    validarNumeroUnico(numero, time, jogadorId) {
      let jogadores = time === 'time1' ? this.jogadoresTime1 : this.jogadoresTime2;
      
      const duplicado = jogadores.some(jogador => jogador.numero === numero && jogador.id !== jogadorId);
      if (duplicado) {
        this.$message.error('Número já está em uso por outro jogador!');
        jogadores.find(jogador => jogador.id === jogadorId).numero = '';
      }
    },
    validarGoleiroUnico(numero, time, jogadorId) {
      if(numero !== 'goleiro') return;
      let jogadores = time === 'time1' ? this.jogadoresTime1 : this.jogadoresTime2;
      
      const duplicado = jogadores.some(jogador => jogador.posicao === 'goleiro' && jogador.id !== jogadorId);
      if (duplicado) {
        this.$message.error('Goleiro já está em uso por outro jogador!');
        jogadores.find(jogador => jogador.id === jogadorId).posicao = '';
      }
    },
    formataJogadores (jogadores, idTime) {
      return jogadores.map(jogador => {
        return {
          jogadorId: jogador.usuario.id,
          timeId: idTime,
          numero: jogador.numero,
          posicao: jogador.posicao
        }
      });
    },
    resetForm() {
      this.$refs.partidaForm.resetFields();
      this.$router.back();
    },
    mudaTime1() {
      this.carregarJogadoresTime1();
    },
    mudaTime2() {
      this.carregarJogadoresTime2();
    },
    async carregarJogadoresTime1() {
      if (this.partida.time1.id) {
        try {
          this.jogadoresTime1 = await jogadorService.buscarPorId(this.partida.time1.id);
        } catch (error) {
          console.error('Erro ao carregar jogadores do Time 1:', error);
        }
      }
    },
    async carregarJogadoresTime2() {
      if (this.partida.time2.id) {
        try {
          this.jogadoresTime2 = await jogadorService.buscarPorId(this.partida.time2.id);
        } catch (error) {
          console.error('Erro ao carregar jogadores do Time 2:', error);
        }
      }
    },
    atualizaJogadores() {
      this.carregarJogadoresTime1();
      this.carregarJogadoresTime2();
    }
  },
  async mounted() {
    try {
      this.id = this.$route.params.id;
      if (this.id) {
        this.partida = await partidaServices.findById(this.id)
        this.partida.administradorPartida = null;
        this.partida.campo = { id: this.partida.campo.id };
        this.partida.time1 = { id: this.partida.time1.id };
        this.partida.time2 = { id: this.partida.time2.id };
        const jogadoresPartida1 = await await jogadorService.buscarPorId(this.partida.time1.id);
        const jogadoresPartida2 = await await jogadorService.buscarPorId(this.partida.time2.id);
        this.jogadoresTime1 = this.partida.jogadoresPartida.filter(jogador => jogador.timeId === this.partida.time1.id).map(j => {
          const jogador = jogadoresPartida1.find(jogador => jogador.usuario.id === j.jogadorId);
          return {...j, ...jogador};
        });
        this.jogadoresTime2 = this.partida.jogadoresPartida.filter(jogador => jogador.timeId === this.partida.time2.id).map(j => {
          const jogador = jogadoresPartida2.find(jogador => jogador.usuario.id === j.jogadorId);
          return {...j, ...jogador};
        });
      }
    
      this.times1 = await timeService.listarTodos();
      this.times2 = await timeService.listarTodosSimples();

      this.campos = await campoServices.listarTodos();
    } catch (error) {
      console.error('Erro ao carregar times ou campos:', error);
    }
  }
};
</script>

<style scoped>
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.el-form-item {
  flex: 1;
  min-width: 200px;
}

.player-card {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  border: 1px solid #484a4b;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

.el-button {
  margin-right: 10px;
}

.margin-top {
  margin-top: 20px;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.container-jogador {
  margin-top: 20px;
  border: 1px solid #484a4b;
  padding: 10px;
  border-radius: 5px;
}

.top-10 {
  margin-top: 10px;
}
</style>
