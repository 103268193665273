<template>
  <div class="tela">
    <el-form :model="time" :rules="rules" ref="timeForm" label-width="0">
      <div class="form-row">
        <el-form-item label="Nome" prop="nome">
          <el-input v-model="time.nome"></el-input>
        </el-form-item>
        <el-form-item label="País" prop="pais">
          <el-input v-model="time.pais"></el-input>
        </el-form-item>
        <el-form-item label="Estado" prop="estado">
          <el-input v-model="time.estado"></el-input>
        </el-form-item>
      </div>
      <div class="form-row">
        <el-form-item label="Cidade" prop="cidade">
          <el-input v-model="time.cidade"></el-input>
        </el-form-item>
        <el-form-item>
          <el-upload
            v-if="!time.foto"
            :file-list="fileList"
            list-type="picture-card"
            :action="uploadAction"
            :headers="{ 'api-key': '3sd5f$%465a1gva56dfg@4w54654wt6w52y4w5245*&(*325y' }"
            :limit="1"
            :auto-upload="true"
            :before-upload="beforeUpload"
            :on-change="handleFileChange"
            accept="image/png,image/jpeg,image/jpg"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div v-else>
            <img :src="time.foto" alt="Foto" style="width: 100px; height: 100px;">
            <el-button @click="handleRemove">
              <el-icon name="close" class="remove"></el-icon>
            </el-button>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div>
      <el-button type="primary" @click="handleSubmit">Salvar</el-button>
      <el-button @click="cancelar">Cancelar</el-button>
    </div>
  </div>
</template>

<script>
import timeService from '@/services/timeService';

export default {
  data() {
    return {
      uploadAction: process.env.VUE_APP_API_URL + '/files/upload',
      fileList: [],
      time: {
        nome: '',
        cidade: '',
        estado: '',
        pais: '',
        foto: '',
      },
      rules: {
        nome: [
          { required: true, message: 'O nome é obrigatório', trigger: 'blur' },
        ],
        pais: [
          { required: true, message: 'O país é obrigatório', trigger: 'blur' },
        ],
        estado: [
          { required: true, message: 'O estado é obrigatório', trigger: 'blur' },
        ],
        cidade: [
          { required: true, message: 'A cidade é obrigatória', trigger: 'blur' },
        ],
      },
      dialogImageUrl: '',
      dialogVisible: false
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.time = await timeService.buscarPorId(this.id);
    }
  },
  methods: {
    async handleSubmit() {
      this.$refs.timeForm.validate(async (valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append('nome', this.time.nome);
          formData.append('cidade', this.time.cidade);
          formData.append('estado', this.time.estado);
          formData.append('pais', this.time.pais);
          formData.append('media', 0);
          if (this.time.foto) {
            formData.append('foto', this.time.foto);
          }
          formData.append('dataCriacao', new Date().toISOString().split('T')[0]);
          formData.append('horaCriacao', new Date().toTimeString().split(' ')[0]);

          if (this.id) {
            await timeService.atualizar(this.id, formData);
            this.$message.success('Atualizado com sucesso');
          } else {
            await timeService.salvar(formData);
            this.$message.success('Salvo com sucesso');
          }
          this.$router.back();
        }
      });
    },
    handleRemove() {
      this.time.foto = null
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPng) {
        this.$message.error('A foto deve estar no formato JPG ou PNG!');
      }
      if (!isLt2M) {
        this.$message.error('A foto não pode ser maior que 2MB!');
      }
      return (isJPG || isPng) && isLt2M;
    },
    handleFileChange(file, raw) {
      this.time.foto = raw[0].response;
    },
    cancelar() {
      this.$router.back();
    }
  },
};
</script>

<style scoped>
.tela {
  margin: 10px;
  padding: 20px;
}

.el-form-item {
  flex: 1;
  min-width: 200px;
}

.el-button {
  margin-right: 10px;
}
</style>
